// 隐私号--话单查询
import { Request } from '@/http/request'

// 话单查询列表
export function getBillList(parameter: any) {
  return Request.axiosInstance.post('/open/bill/list', parameter)
}
// 话单查询列表
export function getByCallId(parameter: any) {
  return Request.axiosInstance.post('/open/bill/get-by-call-id', parameter)
}
// 话单导出
export function billExport(parameter: any) {
  return Request.axiosInstance.post('/open/bill/export', parameter)
}
// 话单重推
export function rePushBill(parameter: any) {
  return Request.axiosInstance.post('/open/bill/re-push-bill', parameter)
}
// ES话单查询列表
export function getEsBillList(parameter: any) {
  return Request.axiosInstance.post('/open/es-bill/list', parameter)
}
// ES话单重推
export function esRePushBill(parameter: any) {
  return Request.axiosInstance.post('/open/es-bill/re-push-bill', parameter)
}
// ES话单导出
export function esBillExport(parameter: any) {
  return Request.axiosInstance.post('/open/es-bill/export', parameter)
}

// ES话单tuisong
export function pushbill(parameter: any) {
  return Request.axiosInstance.post('/open/es-bill/user-re-push-bill', parameter)
}

export default {pushbill, getBillList, getByCallId, billExport, rePushBill, getEsBillList, esRePushBill, esBillExport }